import React, { useEffect, useRef } from 'react';
import "../../Assets/css/home.css";
import { Col, Row, Image, Container, Button } from 'react-bootstrap';

import { BsArrowRightShort } from 'react-icons/bs';
import { NavLink, useHistory } from 'react-router-dom';
// import ReactPlayer from 'react-player'
import Slider from "react-slick";

import Banner1 from "../../Assets/images/platinum/Platinum-Days-30-Days-Banner.webp";
import Banner2 from "../../Assets/images/platinum/banner-2.webp";


const BannerSlider = () => {
  const history = useHistory();
  function kleviyoTracking(params) {
    //Track events for an identified user
    
    window.klaviyo.push(['track', 'Elected President', {
        'Country' : 'United States'
      }]);
    
      window.klaviyo.push(['identify', {
        // Change the line below to dynamically print the user's email.
        'email' : 'matt.kemp@klaviyo-demo.com'
      }]);
      console.log('window.klaviyo',window);
      var item = {
        "ProductName": "demo track om",
        "ProductID": "op123",
        "SKU": "9999999",
        "Categories": "klaviyo rings gtracking",
        "ImageURL": "test.jpg",
        "URL": "999",
        "Brand": "999",
        "Price": "5555",
        "CompareAtPrice": "5555",
      };
      window.klaviyo.push(["track", "Viewed Product", item]);
    }
    useEffect(() => {
      console.log('window?.klaviyo==',window?.klaviyo);
      if (window?.klaviyo) {
        window.klaviyo.push(['identify', {
            'email': "newsletteremail@platinumday.com",
            'name': "newslettername",
            'news_Latter' : "News Latter"
        }])
      };
     if (window?.klaviyo) {
      
       kleviyoTracking()
     }
    
    }, [])
    
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    centerPadding: "20px",
    accessibility: true,
    arrows: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          infinite: true,

        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
        }
      }
    ]

  };


  return (
    <>

      {/* <div className='rcs_home_video_main'>
                  <ReactPlayer url="https://dl2vs6wk2ewna.cloudfront.net/media/adele.mp4" width='100%'
                    height='100%'
                    playing
                    loop
                    muted
                    playsinline
                  />
                </div> */}
      {/* 
      <div className="rcs_hero_img_d">
        <Row className='m-auto w-100'>
          <Col md={12} className='m-auto p-0'>
          


            <div className="rcs_hero_slider str_jeweler_banner">
              <div className="rcs_hero_img">
                <Container className='rcs_custom_home_container'>
                  <Row className='m-auto w-100'>
                    <Col md={12} className='m-auto'>
                      <div className='text-left  ml-1'>
                        <h4 > Love & Engagement </h4>
                        <h1>Diamond Rings</h1>
                        <p >Shop Our Latest Collection of Diamond Engagement Rings</p>
                        <Button variant="outline-dark" className='sj_border_btn' onClick={() => history.push('/collections/engagement-rings')}>Shop Now <BsArrowRightShort /> </Button>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>


          </Col>
        </Row>


      </div> */}

      <div className='bannerup'>
        <div className='banner-slider hide-mob'>
          <Container className='rcs_custom_banner_container'>
            <Row className='w-100 m-0'>

              <Col lg={12}>

                <Slider {...settings} className="rcs_slider_img">
                  <div className='pd_banner_slider_main'>
                    <NavLink to="/engagement-rings">  <img src={Banner1} /> </NavLink>
                  </div>
                  <div className='pd_banner_slider_main'>
                    <NavLink to="/jewelry/fine-jewelry">  <img src={Banner2} /> </NavLink>
                  </div>

                </Slider>

              </Col>

            </Row>
          </Container>
        </div>
      </div>


    </>
  )
}

export default BannerSlider;

